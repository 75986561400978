<template>
  <div class="audio-course">
    <div>
      <v-img
        class="course-cover mx-auto"
        :src="thumbnailUrl"
        width="70vw"
        max-height="70vw" />
    </div>
    <div class="my-2">
      <v-row
        no-gutters
        class="col-12 px-0 py-0"
        justify="space-between"
        align="center">
        <div class="course-title">
          {{ course.title }}
        </div>
        <v-btn
          @click="toggleFavouriteStatus"
          :loading="isToggleFavoriteLoading"
          :disabled="isToggleFavoriteLoading"
          large
          icon>
          <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="course.isFavorite" v-bind:height="'24'" v-bind:width="'24'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12.613 2.053a3.6 3.6 0 00-5.1 0L7 2.573l-.513-.52a3.6 3.6 0 00-5.1 0c-1.414 1.414-1.5 3.8.28 5.614L7 13l5.333-5.333c1.78-1.814 1.694-4.2.28-5.614z" fill="var(--v-primaryColor-base)"/></svg>
          <img
            v-else
            height="24"
            width="24"
            :src="heartIconDefault" />
        </v-btn>
      </v-row>
      <v-row
        no-gutters
        class="col-12 pa-0">
        <v-slider
          loader-height="230"
          track-color="white"
          color="primaryColor"
          :min="0"
          :max="audioInstance.duration"
          hide-details
          :value="currentTime"
          @change="changeAudioPosition">
        </v-slider>
      </v-row>
      <v-row
        no-gutters
        justify="space-between"
        class="col-12 pa-0 time-indicators">
        <div>{{ secondsToMinutes(audioInstance.currentTime.toFixed(0)) }}</div>
        <div>
          -{{
            secondsToMinutes((audioInstance.duration.toFixed(0) || 0) - (audioInstance.currentTime.toFixed(0) || 0))
          }}
        </div>
      </v-row>
      <v-row
        justify="space-between"
        align="center"
        no-gutters
        class="col-12 my-2 py-0 px-0">
        <div>
          <v-btn
            v-if="currentstate == 'paused'"
            @click="playAudio"
            color="primaryColor"
            fab
            depressed
            width="56"
            height="56">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="none" viewBox="0 0 56 56" v-bind:class="'play-icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><rect width="56" height="56" fill="var(--v-primaryColor-base)" rx="28"/><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.667" d="M21.213 16l18.666 12-18.666 12V16z"/></svg>
          </v-btn>
          <v-btn
            @click="pauseAudio"
            v-else
            color="primaryColor"
            fab
            depressed
            width="56"
            height="56">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="none" viewBox="0 0 56 56" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><rect width="56" height="56" fill="var(--v-primaryColor-base)" rx="28"/><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.667" d="M25.333 17.333H20v21.334h5.333V17.333zm10.667 0h-5.333v21.334H36V17.333z"/></svg>
          </v-btn>
          <v-btn
            @click="restartAudio"
            class="ml-5"
            color="offWhite"
            fab
            depressed
            width="44"
            height="44">
            <v-img :src="require('@/assets/audio/restart.png')"></v-img>
          </v-btn>
        </div>
        <CompleteButton
          :course="this.course"
          @completeCourse="$emit('completeCourse')" />
      </v-row>
    </div>
  </div>
</template>

<script>
  import AudioPlayerMixin from '@/components/courses/course-details/audio-player/AudioPlayer.mixin.vue';
  export default {
    mixins: [AudioPlayerMixin],
  };
</script>

<style lang="css" scoped>
  .audio-course {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .course-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.16em;
    text-align: left;
    text-transform: uppercase;
    color: #ffffff;
    max-width: 75%;
  }
  .course-cover {
    box-shadow: 0px 40px 32px -24px rgba(0, 0, 0, 0.4);
    border-radius: 24px;
  }
  ::v-deep .v-slider--horizontal .v-slider__track-container {
    height: 4px !important;
  }
  ::v-deep .theme--light.v-slider .v-slider__track-background,
  ::v-deep .theme--light.v-slider .v-slider__track-fill {
    border-radius: 9999px !important;
  }
  .time-indicators {
    color: white;
    font-size: 12px;
  }
</style>
